import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, of, switchMap, tap } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  downloadFile,
  getFilenameFromContentDisposition,
} from '@shared/utils/download-file';
import { mimeTypes } from '@shared/constants/mime-types';
import { b64DecodeUnicode, joinObjectEntries } from '@shared/utils/common';
import { environment } from '../../../../environments/environment';
import { ControlRecord } from '../../../types/control';
import { RecalculateFromField } from '../../../types/ware';
import { NomenclatureExtraRule } from '../../../types/nomenclature';
import {
  DtImportCopyOptions,
  DtImportDeclaration,
  DtImportJournalResponse,
  DtImportPresentedDocumentItem,
  DtImportWareDetailsItem,
  DtImportWareItem,
  DtImportWarePrecedingDocument,
  DtImportWaresAndDocuments,
} from '../types/dt-import';
import {
  DtImportAddPresentedDocument,
  DtImportAddWareItem,
  DtImportDeleteWareItems,
  DtImportReplacePresentedDocuments,
  DtImportReplaceWares,
  DtImportReplaceWaresAndDocuments,
  DtImportUpdateBlock,
  DtImportUpdatePresentedDocuments,
  DtImportUpdateWareItems,
  SetDtImportDocument,
} from '../store/dt-import.actions';
import { JournalDocumentFilter } from '../../../types/journal';
import { ConditionSearch } from '../../../types/declaration-search';
import { SignPayload } from '../../../types/sign';

@Injectable({
  providedIn: 'root',
})
export class DtImportService {
  API = `${environment.apiUrl}`;
  DT_IMPORT = `${this.API}/dtim`;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
  ) {}

  getJournal({
    size,
    page,
    filter,
    search,
  }: {
    size: number;
    page: number;
    filter: JournalDocumentFilter;
    search: ConditionSearch;
  }) {
    const joinedParams = joinObjectEntries(filter);
    const searchValue =
      search && search.value ? `&${search.condition}=${search.value}` : '';
    return this.http.get<DtImportJournalResponse>(
      `${this.DT_IMPORT}/declaration/getDeclarationsJournal?size=${size}&page=${page}${joinedParams}${searchValue}`,
    );
  }

  createDocument({ userId }: { userId: number }) {
    return this.http.post<DtImportDeclaration>(
      `${this.DT_IMPORT}/declaration/create?userId=${userId}`,
      null,
    );
  }

  getDocumentById(id: number) {
    return this.http.get<DtImportDeclaration>(
      `${this.DT_IMPORT}/declaration/getById?id=${id}`,
    );
  }

  setDocument(id: number) {
    return this.getDocumentById(id).pipe(
      switchMap(payload =>
        this.store.dispatch(new SetDtImportDocument(payload)),
      ),
      tap(v => console.log(v)),
    );
  }

  updateDocumentBlock(payload: Partial<DtImportDeclaration>) {
    const [block] = Object.values(payload);
    return this.http
      .post(`${this.DT_IMPORT}/declaration/update`, block)
      .pipe(
        switchMap(() => this.store.dispatch(new DtImportUpdateBlock(payload))),
      );
  }

  createPresentedDocument({
    declarationId,
    sortIndex,
  }: {
    declarationId: number;
    sortIndex: number;
  }) {
    return this.http
      .post<DtImportPresentedDocumentItem>(
        `${this.DT_IMPORT}/presentedDocument/create?declarationId=${declarationId}&sortIndex=${sortIndex}`,
        null,
      )
      .pipe(
        switchMap(item =>
          this.store.dispatch(new DtImportAddPresentedDocument([item])),
        ),
      );
  }

  deletePresentedDocuments({
    declarationId,
    ids,
  }: {
    declarationId: number;
    ids: number[];
  }) {
    return this.http
      .post<
        DtImportPresentedDocumentItem[]
      >(`${this.DT_IMPORT}/presentedDocument/deleteByIds?declarationId=${declarationId}`, ids)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportReplacePresentedDocuments(items)),
        ),
      );
  }

  copyDeclaration({ wareIds, ...otherOptions }: DtImportCopyOptions) {
    let url = `${this.DT_IMPORT}/declaration/copy`;
    Object.entries(otherOptions).forEach(([key, value], index) => {
      const joinChar = index === 0 ? '?' : '&';
      url += `${joinChar}${key}=${value}`;
    });
    return this.http.post<DtImportDeclaration>(url, wareIds);
  }

  copyPresentedDocuments({
    declarationId,
    ids,
  }: {
    declarationId: number;
    ids: number[];
  }) {
    return this.http
      .post<
        DtImportPresentedDocumentItem[]
      >(`${this.DT_IMPORT}/presentedDocument/copy?declarationId=${declarationId}`, ids)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportAddPresentedDocument(items)),
        ),
      );
  }

  clearWaresRange({
    declarationId,
    ids,
  }: {
    declarationId: number;
    ids: number[];
  }) {
    return this.http
      .post<
        DtImportPresentedDocumentItem[]
      >(`${this.DT_IMPORT}/presentedDocument/clearWareRange?declarationId=${declarationId}`, ids)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdatePresentedDocuments(items)),
        ),
      );
  }

  updatePresentedDocuments(documents: DtImportPresentedDocumentItem[]) {
    return this.http
      .post<
        DtImportPresentedDocumentItem[]
      >(`${this.DT_IMPORT}/presentedDocument/updateAll`, documents)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdatePresentedDocuments(items)),
        ),
      );
  }

  createWareItem({
    declarationId,
    sortIndex,
  }: {
    declarationId: number;
    sortIndex: number;
  }) {
    return this.http
      .post<DtImportWareItem>(
        `${this.DT_IMPORT}/ware/create?declarationId=${declarationId}&sortIndex=${sortIndex}`,
        null,
      )
      .pipe(
        switchMap(item => this.store.dispatch(new DtImportAddWareItem(item))),
      );
  }

  updateWareItems(wares: DtImportWareItem[]) {
    return this.http
      .post<DtImportWareItem[]>(`${this.DT_IMPORT}/ware/updateAll`, wares)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  sortWareItems({
    declarationId,
    sortBy,
  }: {
    declarationId: number;
    sortBy: 'id' | 'code';
  }) {
    return this.http
      .post<DtImportWaresAndDocuments>(
        `${this.DT_IMPORT}/ware/sortWares?declarationId=${declarationId}&sortBy=${sortBy}`,
        null,
      )
      .pipe(
        switchMap(response =>
          this.store.dispatch(new DtImportReplaceWaresAndDocuments(response)),
        ),
      );
  }

  copyWareItem({ id, sortIndex }: { id: number; sortIndex: number }) {
    return this.http
      .post<DtImportWareItem>(
        `${this.DT_IMPORT}/ware/copy?id=${id}&sortIndex=${sortIndex}`,
        null,
      )
      .pipe(
        switchMap(item => this.store.dispatch(new DtImportAddWareItem(item))),
      );
  }

  changeWareItemsSortIndexes({
    declarationId,
    ids,
  }: {
    declarationId: number;
    ids: number[];
  }) {
    return this.http
      .post<DtImportWaresAndDocuments>(
        `${this.DT_IMPORT}/ware/changeSortIndexes?declarationId=${declarationId}`,
        ids,
      )
      .pipe(
        switchMap(response =>
          this.store.dispatch(new DtImportReplaceWaresAndDocuments(response)),
        ),
      );
  }

  deleteWareItems({
    declarationId,
    ids,
  }: {
    declarationId: number;
    ids: number[];
  }) {
    return this.http
      .post<DtImportWaresAndDocuments>(
        `${this.DT_IMPORT}/ware/deleteByIds?declarationId=${declarationId}`,
        ids,
      )
      .pipe(
        switchMap(response =>
          this.store.dispatch(new DtImportDeleteWareItems(response)),
        ),
      );
  }

  getWareDetailsItems({ wareId }: { wareId: number }) {
    return this.http.get<DtImportWareDetailsItem[]>(
      `${this.DT_IMPORT}/wareDetails/getByWareId?wareId=${wareId}`,
    );
  }

  createWareDetailsItem({
    wareId,
    sortIndex,
  }: {
    wareId: number;
    sortIndex: number;
  }) {
    return this.http.post<DtImportWareDetailsItem>(
      `${this.DT_IMPORT}/wareDetails/create?wareId=${wareId}&sortIndex=${sortIndex}`,
      null,
    );
  }

  updateWareDetailsItems(wareDetails: unknown) {
    return this.http.post<DtImportWareDetailsItem[]>(
      `${this.DT_IMPORT}/wareDetails/updateAll`,
      wareDetails,
    );
  }

  deleteWareDetailsItem(id: number, wareId: number) {
    return this.http.post(
      `${this.DT_IMPORT}/wareDetails/deleteByIds?wareId=${wareId}`,
      [id],
    );
  }

  downloadPdf(id: number) {
    return this.http
      .get(`${this.DT_IMPORT}/declaration/pdf?id=${id}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap(res => {
          if (res.body) {
            const base64string = getFilenameFromContentDisposition(
              res.headers.get('content-disposition'),
            );
            const name = b64DecodeUnicode(decodeURIComponent(base64string));
            downloadFile({
              data: res.body,
              type: mimeTypes.APPLICATION_PDF,
              name,
            });
          }
        }),
        catchError(e => {
          return of(null);
        }),
      );
  }

  downloadXml(id: number) {
    return this.http
      .get(`${this.DT_IMPORT}/declaration/export?declarationId=${id}`, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        tap(res => {
          if (res.body) {
            downloadFile({
              data: res.body,
              type: mimeTypes.APPLICATION_XML,
              name: getFilenameFromContentDisposition(
                res.headers.get('content-disposition'),
              ),
            });
          }
        }),
      );
  }

  validateDocument(id: number) {
    return this.http.get<ControlRecord[]>(
      `${this.DT_IMPORT}/declaration/flk?declarationId=${id}`,
    );
  }

  downloadXlsxTemplate() {
    return this.http
      .get(`${this.DT_IMPORT}/declaration/downloadXlsxTemplate`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap(res => {
          if (res.body) {
            downloadFile({
              data: res.body,
              type: mimeTypes.EXCEL,
              name: getFilenameFromContentDisposition(
                res.headers.get('content-disposition'),
              ),
            });
          }
        }),
      );
  }

  downloadXlsx(id: number) {
    return this.http
      .get(`${this.DT_IMPORT}/declaration/downloadXlsx?declarationId=${id}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap(res => {
          if (res.body) {
            downloadFile({
              data: res.body,
              type: mimeTypes.EXCEL,
              name: getFilenameFromContentDisposition(
                res.headers.get('content-disposition'),
              ),
            });
          }
        }),
      );
  }

  uploadXlsx(payload: FormData) {
    return this.http
      .post<DtImportWaresAndDocuments>(
        `${this.DT_IMPORT}/declaration/uploadXlsx`,
        payload,
      )
      .pipe(
        switchMap(response =>
          this.store.dispatch(new DtImportReplaceWaresAndDocuments(response)),
        ),
      );
  }

  copyInOtherWares(payload: { ids: number[]; values: Record<string, any> }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/copyInOtherWares`, payload)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  recalculateStatisticalCost(declarationId: number) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/recalculateStatisticalCost?declarationId=${declarationId}`, null)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  changePerformer({
    otherUserId,
    declarationId,
  }: {
    otherUserId: number;
    declarationId: number;
  }) {
    return this.http.post(
      `${this.DT_IMPORT}/declaration/setUserId?otherUserId=${otherUserId}&declarationId=${declarationId}`,
      null,
    );
  }

  recalculateWeight({
    declarationId,
    weight,
    fromField,
  }: {
    declarationId: number;
    weight: number;
    fromField: RecalculateFromField;
  }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/calculateWeight?declarationId=${declarationId}&weight=${weight}&fromField=${fromField}`, null)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  fillNetWeightNoPackFromNetWeight({
    declarationId,
  }: {
    declarationId: number;
  }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/fillNetWeightNoPackFromNetWeight?declarationId=${declarationId}`, null)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  recalculateGrossWeightByAddingPackage({
    declarationId,
    commonGrossWeight,
  }: {
    declarationId: number;
    commonGrossWeight: number;
  }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/recalculateGrossWeightByAddingPackage?declarationId=${declarationId}&commonGrossWeight=${commonGrossWeight}`, null)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  recalculateInvoiceCostInBYN({ declarationId }: { declarationId: number }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/recalculateInvoiceCostInBYN?declarationId=${declarationId}`, null)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  fillAddTnVedWithZero({ declarationId }: { declarationId: number }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/fill0000AddTnVed?declarationId=${declarationId}`, null)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  applyAddTnVedRule({
    declarationId,
    rules,
  }: {
    declarationId: number;
    rules: NomenclatureExtraRule[];
  }) {
    return this.http
      .post<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/applyAddTnVedRule?declarationId=${declarationId}`, rules)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportUpdateWareItems(items)),
        ),
      );
  }

  searchInWares({
    declarationId,
    value,
  }: {
    declarationId: number;
    value: string;
  }) {
    return this.http
      .get<
        DtImportWareItem[]
      >(`${this.DT_IMPORT}/ware/getByDeclarationIdAndTitleOrTnVed?declarationId=${declarationId}&value=${value}`)
      .pipe(
        switchMap(items =>
          this.store.dispatch(new DtImportReplaceWares(items)),
        ),
      );
  }

  findWaresInDivision({ search }: { search: string }) {
    return this.http.post<DtImportWareItem[]>(
      `${this.DT_IMPORT}/ware/getByDivisionIdAndTitle`,
      search,
    );
  }

  createWareFromExisting({
    id,
    sortIndex,
    declarationId,
  }: {
    id: number;
    sortIndex: number;
    declarationId: number;
  }) {
    return this.http
      .post<DtImportWareItem>(
        `${this.DT_IMPORT}/ware/createFromExists?declarationId=${declarationId}&id=${id}&sortIndex=${sortIndex}`,
        null,
      )
      .pipe(
        switchMap(item => this.store.dispatch(new DtImportAddWareItem(item))),
      );
  }

  createWarePrecedingDocument({
    wareId,
    sortIndex,
  }: {
    wareId: number;
    sortIndex: number;
  }) {
    return this.http.post<DtImportWarePrecedingDocument>(
      `${this.DT_IMPORT}/precedingDocument/create?wareId=${wareId}&sortIndex=${sortIndex}`,
      null,
    );
  }

  deleteWarePrecedingDocuments({
    wareId,
    ids,
  }: {
    wareId: number;
    ids: number[];
  }) {
    return this.http.post<DtImportWarePrecedingDocument>(
      `${this.DT_IMPORT}/precedingDocument/deleteByIds?wareId=${wareId}`,
      ids,
    );
  }

  validatePayments(id: number) {
    return this.http.get<ControlRecord[]>(
      `${this.DT_IMPORT}/declaration/validatePayments?id=${id}`,
    );
  }

  calculatePayments(id: number) {
    return this.http.post(
      `${this.DT_IMPORT}/declaration/calculatePayments?id=${id}`,
      null,
    );
  }

  calculateIncludingCosts(id: number) {
    return this.http.post(
      `${this.DT_IMPORT}/declaration/calculateIncludingCosts?id=${id}`,
      null,
    );
  }

  setTrash({ trash, id }: { trash: boolean; id: number }) {
    return this.http.post(
      `${this.DT_IMPORT}/declaration/setTrash?trash=${trash}&id=${id}`,
      null,
    );
  }

  getDeclarationDetailsInJournal(id: number) {
    return this.http.get<DtImportDeclaration>(
      `${this.DT_IMPORT}/declaration/getDeclarationDetailsInJournal?id=${id}`,
    );
  }

  sendToNces(payload: SignPayload) {
    return this.http.post<void>(
      `${this.DT_IMPORT}/declaration/sendToNces`,
      payload,
    );
  }

  getXmlForSign(id: number) {
    return this.http.get<{ xml: string; guid: string }>(
      `${this.DT_IMPORT}/declaration/xmlForSign?declarationId=${id}`,
    );
  }

  createDTS(id: number) {
    return this.http.post<number[]>(
      `${this.DT_IMPORT}/declaration/createDts?id=${id}`,
      null,
    );
  }
}
