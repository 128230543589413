import {
  DtImportBlocksExceptWares,
  DtImportDeclaration,
  DtImportPresentedDocumentItem,
  DtImportWareItem,
  DtImportWaresAndDocuments,
} from '../types/dt-import';

export class SetDtImportDocument {
  static readonly type = '[DtImport] Set DtImport Document';
  constructor(public declaration: DtImportDeclaration) {}
}

export class DtImportUpdateBlock {
  static readonly type = '[DtImport] Update Block';
  constructor(public block: Partial<DtImportBlocksExceptWares>) {}
}

export class DtImportAddPresentedDocument {
  static readonly type = '[DtImport] Add Presented Document';
  constructor(public presentedDocumentItem: DtImportPresentedDocumentItem[]) {}
}

export class DtImportUpdatePresentedDocuments {
  static readonly type = '[DtImport] Update Presented Documents';
  constructor(public presentedDocuments: DtImportPresentedDocumentItem[]) {}
}

export class DtImportReplacePresentedDocuments {
  static readonly type = '[DtImport] Replace Presented Documents';
  constructor(public presentedDocuments: DtImportPresentedDocumentItem[]) {}
}

export class DtImportReplaceWares {
  static readonly type = '[DtImport] Replace Wares';
  constructor(public wareItems: DtImportWareItem[]) {}
}

export class DtImportAddWareItem {
  static readonly type = '[DtImport] Add Ware Item';
  constructor(public wareItem: DtImportWareItem) {}
}

export class DtImportUpdateWareItems {
  static readonly type = '[DtImport] Update Ware Items';
  constructor(public wareItems: DtImportWareItem[]) {}
}

export class DtImportDeleteWareItems {
  static readonly type = '[DtImport] Delete Ware Item';
  constructor(public documentsAndWares: DtImportWaresAndDocuments) {}
}

export class DtImportReplaceWaresAndDocuments {
  static readonly type = '[DtImport] Replace Blocks';
  constructor(public documentsAndWares: DtImportWaresAndDocuments) {}
}
